import React from 'react';
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded';

/**
 * Filter
 */
export const DEFAULT_FILTER = {
    merchantType: 't',
    holderType: [],
    activities: [],
    merchantState: [],
    merchantPlaceState: [],
    billState: [],
    draw: {},
    sectors: []
};

/**
 * Filter keys
 */
export const keysForFilter = {
    merchantType: {
        p: 'Passager',
        t: 'Titulaire'
    },
    holderType: {
        ta: 'Abonné',
        tna: 'Non abonné'
    },
    activities: ['Alimentaire', 'Primeur', 'Manufacturé', 'Démonstrateur/Posticheur'],
    merchantState: {
        here: 'Présent',
        not_here: 'Absent'
    },
    merchantPlaceState: {
        placed: 'Placé',
        not_placed: 'Non placé'
    },
    billState: {
        invoiced: 'Non réglé',
        paid: 'Réglé'
    }
};

/**
 *
 * @param {*} drawer
 * @param {*} merchantType
 * @param {*} onClick
 * @returns
 */
export function headers(drawer, merchantType, onClick) {
    const header = [];

    header.push({
        id: 'presence',
        align: 'left',
        label: <PlaylistAddCheckRoundedIcon color={merchantType !== 't' ? 'default' : 'primary'} />,
        padding: 'checkbox',
        onClick: onClick,
        disabled: merchantType !== 't',
        style: { paddingLeft: 9 }
    });

    header.push({
        id: 'place',
        align: 'left',
        label: 'place',
        sortable: true
        //style: { width: '10%' }
    });
    header.push({
        id: 'socialeReason',
        align: 'left',
        label: 'Commerçant',
        sortable: true
        //style: { width: '50%' }
    });

    if (drawer && merchantType === 'p') {
        header.push({
            id: 'draw_order',
            align: 'right',
            label: 'Ordre',
            sortable: true
            //style: { width: 42 }
        });
    } else if (!drawer) {
        header.push({
            id: 'points',
            align: 'right',
            label: 'Points',
            sortable: true
            //style: { width: 42 }
        });
    }

    // header.push({
    //     id: 'presence',
    //     align: 'center',
    //     label: 'Présence',
    //     sortable: false
    // });
    header.push({
        id: 'placement',
        align: 'center',
        label: '',
        sortable: false
        //style: { width: '30%' }
    });
    // header.push({
    //     id: 'bill',
    //     align: 'center',
    //     label: 'Encaissement',
    //     sortable: false
    //     //style: { width: 170 }
    // });

    return header;
}

/**
 *
 * @param {*} props
 * @returns
 */
export const DEFAULT_STATE = props => ({
    ...props,
    currentMerchant: false,
    mounted: true,
    drawer: false,
    openFilter: false,
    openMap: false,
    openPaymentMethod: false,
    openQuickPayment: false,
    openBulk: false,
    beginAt: '',
    listOfPlaceNumbers: [],
    currentFilter: DEFAULT_FILTER,
    searchInput: '',
    page: 0,
    rows: [],
    record: 0,
    order: 'asc',
    orderBy: 'place',
    loading: true,
    openDraw: false,
    openDrawResult: false,
    openSender: false,
    currentBill: false,
    openQuickPayment: false,
    sectors: []
});
