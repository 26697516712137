import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { combineStyles } from 'common/styles';
import { makeStyles } from '@material-ui/styles';
import {
    Switch,
    Button,
    Grid,
    ClickAwayListener,
    Grow,
    Popper,
    MenuItem,
    MenuList,
    Paper,
    Typography,
    Divider,
    Checkbox,
    Link,
    CircularProgress,
    ListItemText
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BackspaceIcon from '@material-ui/icons/Backspace';
import StorefrontIcon from '@material-ui/icons/Storefront';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PersonIcon from '@material-ui/icons/Person';
import {
    updateAttendanceRegister,
    getMerchant,
    unsetMerchantFromPlace,
    getMerchantDetail
} from './http';
import { isMobile } from 'react-device-detect';

/**
 *
 * @param {*} props
 * @returns
 */
export const Label = props => {
    const { className, children, ...rest } = props;

    return (
        <div className={className} {...rest}>
            <Typography gutterBottom variant="subtitle2">
                {children}
            </Typography>
            <Divider />
        </div>
    );
};

// Attendance Button

export const AttendanceButton = props => {
    const { id, marketDayId, type, isPresent, update, isBlocked } = props;
    const [checked, setChecked] = useState(isPresent);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            update.row.loading = 'y';
            update.fnc(update.row);

            updateAttendanceRegister(id, marketDayId, type, checked ? '0' : '1')
                .then(() => {
                    setLoading(false);
                    setChecked(!checked);

                    getMerchant(id, marketDayId)
                        .then(res => {
                            update.row = res;
                            update.fnc(update.row);
                        })
                        .catch(err => console.error(err));
                })
                .catch(err => console.error(err));
        }
    }, [loading]);

    return (
        <Checkbox
            disabled={loading || isBlocked}
            key={`switch-${id}`}
            checked={checked}
            onChange={() => setLoading(true)}
            inputProps={{ 'aria-labelledby': `switch-${id}` }}
        />
    );

    // return (
    //     <Typography component="div" variant="body2">
    //         <Grid
    //             component="label"
    //             container
    //             alignItems="center"
    //             justifyContent="center"
    //             justify="center"
    //             spacing={1}>
    //             <Grid item>NON</Grid>
    //             <Grid item>
    //                 <Switch
    //                     disabled={loading}
    //                     color="secondary"
    //                     key={`switch-${id}`}
    //                     size="small"
    //                     checked={checked}
    //                     onChange={() => setLoading(true)}
    //                 />
    //             </Grid>
    //             <Grid item>OUI</Grid>
    //         </Grid>
    //     </Typography>
    // );

    // return (
    //     <Switch
    //         disabled={loading}
    //         color="secondary"
    //         key={`switch-${id}`}
    //         size="small"
    //         checked={checked}
    //         onChange={() => setLoading(true)}
    //     />
    // );
};

// Placement Button

const PlacementButtonStyles = makeStyles(theme => ({
    popper: {
        zIndex: 9
    },
    button: {
        width: 64
    }
}));

export const PlacementButton = props => {
    const { loading, isPresent, paid, isPlaced, isHolder, fnc, marketDayId, update, id } = props;
    const [unseting, setUnseting] = useState(false);
    const classes = PlacementButtonStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        if (unseting) {
            update.row.loading = 'y';
            update.fnc(update.row);

            unsetMerchantFromPlace(update.row)
                .then(res => {
                    setUnseting(false);

                    if (res) {
                        alert(
                            `${res.marchand} a été enlevé de la place.\nPlace ${res.place.sector}${res.place.numero} disponible.`
                        );
                    }

                    getMerchant(id, marketDayId)
                        .then(res => {
                            update.row = res;
                            update.fnc(update.row);
                        })
                        .catch(err => console.error(err));
                })
                .catch(err => {
                    alert.error(err);
                    setUnseting(false);
                });
        }
    }, [unseting]);

    return (
        <React.Fragment>
            {paid ? null : (
                <>
                    <Button
                        disabled={loading || unseting}
                        variant="outlined"
                        size="small"
                        color="primary"
                        ref={anchorRef}
                        onClick={isPlaced && !isHolder ? handleToggle : update.doPlacement}
                        className={classes.button}
                        startIcon={isPlaced && !isHolder ? <ArrowDropDownIcon /> : null}>
                        {/* <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item style={{ textAlign: 'right' }}>
                        Modifier
                    </Grid>
                </Grid> */}
                        <StorefrontIcon fontSize="small" />
                    </Button>
                    {isPlaced && !isHolder ? (
                        <Popper
                            className={classes.popper}
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom'
                                    }}>
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList>
                                                <MenuItem
                                                    className={classes.do}
                                                    key={2}
                                                    size="small"
                                                    onClick={update.doPlacement}>
                                                    Placer/Déplacer {paid}
                                                    <StorefrontIcon
                                                        color="secondary"
                                                        style={{ paddingLeft: 7 }}
                                                    />
                                                </MenuItem>
                                                {!isHolder && (
                                                    <MenuItem
                                                        key={3}
                                                        size="small"
                                                        disabled={!isPlaced}
                                                        onClick={() => setUnseting(true)}>
                                                        Annuler{' '}
                                                        <BackspaceIcon
                                                            style={{ paddingLeft: 7, color: 'red' }}
                                                        />
                                                    </MenuItem>
                                                )}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    ) : null}
                </>
            )}
        </React.Fragment>
    );
};

// Pay Button

const PayButtonStyles = makeStyles(
    combineStyles(theme => ({
        popper: {
            zIndex: 9
        },
        button: {
            width: 64
        }
    }))
);

export const PayButton = props => {
    const { loading, paid, isPlaced, fnc, ...rest } = props;
    const classes = PayButtonStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                disabled={loading}
                variant="outlined"
                size="small"
                color="primary"
                ref={paid ? anchorRef : null}
                onClick={paid ? handleToggle : isPlaced ? () => fnc.payment() : () => fnc.quick()}
                className={clsx(classes.button, {
                    [classes.paid]: paid && !loading,
                    [classes.unpaid]: !paid && !loading
                })}
                endIcon={paid ? <ArrowDropDownIcon /> : null}>
                {/* <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item style={{ textAlign: 'right' }}>
                        {paid ? 'Payée' : 'Encaisser'}
                    </Grid>
                </Grid> */}
                {paid ? <CheckCircleIcon fontSize="small" /> : <EuroSymbolIcon fontSize="small" />}
            </Button>
            <Popper
                className={classes.popper}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <MenuItem size="small" onClick={() => fnc.show()}>
                                        Afficher <PictureAsPdfIcon style={{ paddingLeft: 7 }} />
                                    </MenuItem>
                                    <MenuItem size="small" onClick={() => fnc.send()}>
                                        Envoyer par mail{' '}
                                        <SendRoundedIcon style={{ paddingLeft: 7 }} />
                                    </MenuItem>
                                    {isMobile && (
                                        <MenuItem size="small">
                                            Imprimer <PictureAsPdfIcon style={{ paddingLeft: 7 }} />
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};

export const SocialeReasonDetail = props => {
    const { id, socialeReason, siret, alert, ...rest } = props;
    const classes = PayButtonStyles();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [countMissingData, setCountMissingData] = useState(0);
    const [countBillDelayed, setCountBillDelayed] = useState(0);
    const [expiredInsurance, setExpiredInsurance] = useState(false);
    const anchorRef = React.useRef(null);

    useEffect(() => {
        if (open) {
            setLoading(true);
            getMerchantDetail(id)
                .then(res => {
                    setCountMissingData(res.missed_data);
                    setCountBillDelayed(res.late_bills);
                    setExpiredInsurance(res.assurancePerimee);
                })
                .finally(() => setLoading(false));
        }
    }, [open]);

    const handleToggle = e => {
        e && e.preventDefault();

        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <Link
                href="#"
                onClick={handleToggle}
                style={alert ? { color: 'red' } : {}}
                ref={anchorRef}>
                {socialeReason}
            </Link>
            <Popper
                className={classes.popper}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                {loading ? (
                                    <div style={{ padding: 30 }}>
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                textAlign: 'right',
                                                paddingTop: 10,
                                                paddingRight: 10
                                            }}>
                                            <Button onClick={handleClose} size="small">
                                                Fermer
                                            </Button>
                                        </div>
                                        <MenuList>
                                            {countMissingData > 0 ? (
                                                <MenuItem
                                                    size="small"
                                                    component={RouterLink}
                                                    to={`/merchants/get/${siret}/missed-data`}>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={2}
                                                        justifyContent="space-between"
                                                        justify="space-between">
                                                        <Grid item>
                                                            <ListItemText
                                                                primary={
                                                                    <>
                                                                        <strong>
                                                                            {countMissingData}
                                                                        </strong>{' '}
                                                                        donnée(s) manquante(s) ou
                                                                        erronée(s)
                                                                    </>
                                                                }
                                                                secondary={
                                                                    expiredInsurance
                                                                        ? 'Dont assurance perimée'
                                                                        : null
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <ArrowForwardIcon />
                                                        </Grid>
                                                    </Grid>
                                                </MenuItem>
                                            ) : null}
                                            {countBillDelayed > 0 ? (
                                                <MenuItem
                                                    size="small"
                                                    component={RouterLink}
                                                    to={`/merchants/get/${siret}/facture`}>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={2}
                                                        justifyContent="space-between"
                                                        justify="space-between">
                                                        <Grid item>
                                                            <strong>{countBillDelayed}</strong>{' '}
                                                            facture(s) en retard
                                                        </Grid>
                                                        <Grid item>
                                                            <ArrowForwardIcon />
                                                        </Grid>
                                                    </Grid>
                                                </MenuItem>
                                            ) : null}

                                            <MenuItem
                                                size="small"
                                                component={RouterLink}
                                                to={`/merchants/get/${siret}/profil`}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                    justifyContent="space-between"
                                                    justify="space-between">
                                                    <Grid item>
                                                        Aller sur la fiche du commerçant
                                                    </Grid>
                                                    <Grid item>
                                                        <PersonIcon color="primary" />
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>
                                        </MenuList>
                                    </>
                                )}
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};
