import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import env from 'common/env';
import {
    Grid,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    InputAdornment,
    InputBase,
    Button
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';

import { List, MarketMap } from './components';
import FilterForm from './components/FilterForm';
import { Dialog as DigitDialog } from 'components';

const styles = theme => ({
    appBar: {
        position: 'relative'
    },
    subContent: {
        padding: theme.spacing(2)
    },
    filter: {
        padding: theme.spacing(2)
    },
    input: {
        padding: theme.spacing(2),
        flex: 1
    },
    title: {
        color: 'white',
        marginLeft: theme.spacing(2),
        flex: 1
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class DigitMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            list: [],
            page: 1,
            numberOfPages: 0,
            searchValue: '',
            openFilter: false,
            placesState: [],
            activities: [],
            merchantType: [],
            sectors: [],
            lastPlace: null,
            loading: false,
            currentPlace: {}
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        (prevProps.marchand.marchand_id != this.props.marchand.marchand_id ||
            this.state.page != prevState.page ||
            this.state.searchValue != prevState.searchValue) &&
            this.props.marchand.marchand_id &&
            this.getData();

        if (
            prevState.placesState !== this.state.placesState ||
            prevState.activities !== this.state.activities ||
            prevState.merchantType !== this.state.merchantType ||
            prevState.sectors !== this.state.sectors
        ) {
            this.getData();
        }
    };

    componentDidMount = () => {
        //this.getData();
    };

    handlePagination = page => {
        this.setState(prevState => ({
            page: prevState.page + page,
            isGenerating: true
        }));
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            isGenerating: true,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            isGenerating: true,
            page: 1
        });
    };

    getData = () => {
        const { searchValue, page, placesState, merchantType, activities, sectors } = this.state;

        const formData = new FormData();

        //formData.append("page", page);
        formData.append('marchand_id', this.props.marchand.marchand_id);
        formData.append('search', searchValue);
        // formData.append('page', page);
        const filter = {
            placesState,
            merchantType,
            activities,
            sectors
        };
        formData.append('filter', JSON.stringify(filter));

        fetch(`${env}/placement/get-places-journalmarket/${this.props.id}`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    isGenerating: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    //alert("Echec de la récupération des données")
                } // Handle the error response object
            );
    };

    doPlacement = (place, force = false) => {
        const _windows = window.prompt(
            `${this.props.marchand.raisonSociale} va être placé sur la place ${place.numero}.\nConfirmer le nombre de mètres ?`,
            parseInt(place.metres)
        );

        if (!_windows) {
            return;
        }

        this.setState({ loading: true, currentPlace: place });

        const meters = Number(_windows);

        if (!meters) {
            alert('Veuillez saisir un numéro.');
            this.doPlacement(place);
            return;
        }

        const formData = new FormData();

        formData.append('marchand_id', this.props.marchand.marchand_id);
        formData.append('meters', meters);
        formData.append('journalplace_id', place.id);
        formData.append('force', force);
        fetch(`${env}/placement/do-placement-journalmarket/${this.props.id}`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                // alert(
                //     `${data.marchand} a été placé sur la place ${data.place.sector}${data.place.numero}.`
                // );
                if (data.splited) {
                    alert(
                        `Place ${data.place.sector}${data.splited.numero} (${data.splited.metres} mètres restant) créée.`
                    );
                }
                this.setState({ lastPlace: data.place, loading: false });
                this.close();
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    close = () => {
        this.setState({
            list: [],
            searchValue: '',
            numberOfPages: 0,
            page: 1
        });

        this.props.close(false);
    };

    handleFilters = (k, v) => {
        const arr = [...this.state[k]];
        const index = arr.indexOf(v);
        index === -1 ? arr.push(v) : arr.splice(index, 1);

        this.setState({
            [k]: arr
        });
    };

    render() {
        const {
            classes,
            list,
            numberOfPages,
            page,
            searchValue,
            isGenerating,
            openFilter,
            activities,
            placesState,
            merchantType,
            sectors,
            lastPlace,
            currentPlace,
            loading
        } = this.state;

        const { sectors: sectorsAvailable } = this.props;

        return (
            <>
                <DigitDialog title="Placement" open={loading} loading={loading}>
                    <Typography variant="body1">
                        Placement du commerçant sur la place {currentPlace.numero} en cours...
                    </Typography>
                </DigitDialog>
                <FilterForm
                    open={openFilter}
                    activities={{
                        set: v => this.handleFilters('activities', v),
                        get: activities
                    }}
                    close={() => this.setState({ openFilter: !openFilter })}
                    placesState={{
                        set: v => this.handleFilters('placesState', v),
                        get: placesState
                    }}
                    merchantType={{
                        set: v => this.handleFilters('merchantType', v),
                        get: merchantType
                    }}
                    sectors={{
                        set: v => this.handleFilters('sectors', v),
                        get: sectors,
                        availables: sectorsAvailable
                    }}
                />
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.close}
                    TransitionComponent={Transition}>
                    <AppBar color="secondary" className={classes.appBar}>
                        <Toolbar variant="dense">
                            <IconButton edge="start" color="inherit" onClick={this.close}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Plan du marché
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container direction="row" alignItems="stretch">
                        <Grid item xs={12} sm={7}>
                            <Grid item>
                                <InputBase
                                    className={classes.input}
                                    placeholder="Rechercher une place ou un commerçant"
                                    fullWidth
                                    onChange={this.handleSearch}
                                    value={searchValue}
                                    color="primary"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Button
                                                onClick={() =>
                                                    this.setState({ openFilter: !openFilter })
                                                }
                                                startIcon={<FilterListIcon />}>
                                                Filtrer
                                            </Button>
                                            <IconButton>
                                                {isGenerating ? (
                                                    <CircularProgress size={'1.5rem'} />
                                                ) : (
                                                    <SearchIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={this.clearSearch}>
                                                <ClearIcon style={{ color: 'red' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <MarketMap
                                    args={{
                                        searchValue: searchValue,
                                        page: page,
                                        marchandId: this.props.marchand.marchand_id
                                    }}
                                    doPlacement={this.doPlacement}
                                    id={this.props.id}
                                    filter={{
                                        activities,
                                        placesState,
                                        merchantType,
                                        sectors
                                    }}
                                    lastPlace={lastPlace}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <List
                                list={list}
                                numberOfPages={numberOfPages}
                                page={page}
                                handlePagination={this.handlePagination}
                                doPlacement={this.doPlacement}
                            />
                        </Grid>
                    </Grid>
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles)(DigitMap);
